export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      redirect: { name: 'Inicio' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'lector',
          name: 'Lector',
          component: () => import('@/views/Lector/Index'),
        },
        {
          path: 'tickets',
          name: 'Tickets',
          component: () => import('@/views/Tickets/TicketsTable'),
        },
        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'sistema',
          meta: { label: 'Sistema', is_admin: true },
          redirect: '/sistema/usuarios',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'usuarios',
              meta: { label: 'Usuarios', is_admin: true },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado de Usuarios',
                  path: '',
                  component: () => import('@/views/Usuarios/UsuariosTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Usuario',
                  component: () => import('@/views/Usuarios/UsuariosCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Usuario',
                  component: () => import('@/views/Usuarios/UsuariosEdit'),
                },
                {
                  path: 'clearpass/:id',
                  name: 'Blanquear Usuario',
                  component: () => import('@/views/Usuarios/UsuariosClearPass'),
                },
              ],
            },
            // {
            //   path: 'lectores',
            //   meta: { label: 'Lectores' },
            //   component: {
            //     render(c) {
            //       return c('router-view')
            //     },
            //   },
            //   children: [
            //     {
            //       name: 'Listado de Lectores',
            //       path: '',
            //       component: () => import('@/views/Lectores/LectoresTable'),
            //     },
            //     {
            //       path: 'create',
            //       name: 'Nuevo Lector',
            //       component: () => import('@/views/Lectores/LectoresCreate'),
            //     },
            //     {
            //       path: 'edit/:id',
            //       name: 'Editar Lector',
            //       component: () => import('@/views/Lectores/LectoresEdit'),
            //     },
            //     {
            //       path: 'clearpass/:id',
            //       name: 'Blanquear Lector',
            //       component: () => import('@/views/Lectores/LectoresClearPass'),
            //     },
            //   ],
            // },
          ],
        },
        {
          path: 'reboot',
          name: 'Reboot',
          component: () => import('@/views/Dashboard/Reboot'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
  ],
}
