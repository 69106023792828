import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { API } from '@/plugins/api'

Vue.use(Vuex)
const storageKey = 'token'
const cookieName = 'cae18158bda2c54186f025c90a818082'

// Recibe el token encriptado o genera uno
const encryptionToken = Cookies.get(cookieName) || uuidv4()
// Guarda la cookie encriptada en una cookie segura
Cookies.set(cookieName, encryptionToken, { expires: 180 })
const vuexPersistence = new VuexPersistence({
  // Edito le funcionamiento del storage, reescribiendo funciones
  storage: {
    getItem: () => {
      const store = window.sessionStorage.getItem(storageKey)

      if (store) {
        try {
          // Desencripta el store recibido desde el storage, usando el token de la cookie cifrada
          const bytes = Crypto.AES.decrypt(store, encryptionToken)

          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
        } catch (e) {
          // error si todo falla
          window.sessionStorage.removeItem(storageKey)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encripta el store
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()

      // Guarda el store encriptado
      return window.sessionStorage.setItem(storageKey, store)
    },
    removeItem: () => window.sessionStorage.removeItem(storageKey),
  },
})

export default new Vuex.Store({
  state: {
    user: {},
    token: null,
    status: false,
    roles: [],
  },
  mutations: {
    auth_success(state, { user, token, roles }) {
      state.user = user
      state.token = token
      state.roles = roles
      state.status = true
    },
    logout(state) {
      state.user = {}
      state.token = null
      state.status = false
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        API.post('api/auth/login', user)
          .then((response) => {
            const user = response.data.data.usuario
            const token = response.data.data.token
            const roles = response.data.data.roles
            commit('auth_success', { user, token, roles })
            resolve(response)
          })
          .catch((error) => {
            sessionStorage.removeItem(storageKey)
            reject(error)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        API.post('api/auth/logout')
          .then(() => {
            commit('logout')
            sessionStorage.removeItem(storageKey)
            Cookies.remove(cookieName)
            resolve()
          })
          .catch(() => {
            commit('logout')
            sessionStorage.removeItem(storageKey)
            Cookies.remove(cookieName)
            resolve()
          })
      })
    },
    logoutClear({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        sessionStorage.removeItem(storageKey)
        Cookies.remove(cookieName)
        resolve()
      })
    },
  },
  getters: {
    authStatus: (state) => state.status,
    getUserName: (state) => state.user.apellido + ', ' + state.user.nombre,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getRoles: (state) => state.roles,
    getRole: (state) => state.user.role_id,
  },
  plugins: [vuexPersistence.plugin],
})
