import Vue from 'vue'
import { API } from '@/plugins/api'

Vue.prototype.$sendToast = function (tipo, texto, posicion = 'top-right') {
  this.$toast(texto, {
    type: tipo,
    timeout: 5000,
    position: posicion,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
    queue: false,
  })
}
Vue.prototype.$validateState = function (ref) {
  if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
    return !this.errors.has(ref)
  }
  return null
}
Vue.prototype.$fetchVuetable = async function (apiUrl, httpOptions) {
  const datos = await API.get(apiUrl, httpOptions)
  return datos.data
}
